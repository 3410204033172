<template>
  <div>
    <el-data-table ref="table" v-bind="tableConfig" :columns="columns">
    </el-data-table>

    <el-dialog :title="title" :visible.sync="dialogFormVisible" :close-on-press-escape="false" :close-on-click-modal="false"  :before-close="closeDialog">
      <el-form :model="form" :rules="rules" ref="ruleUser">
                    <el-form-item label='序号' prop='id' :label-width="formLabelWidth" v-if="false">
              <el-input placeholder='请输入序号' clearable autocomplete="off" v-model='form.id' size="small"  />
            </el-form-item>
            <el-form-item label='帮助分类' prop='typeid' :label-width="formLabelWidth">
                <el-select v-model="form.typeid" placeholder="请选择">
                    <el-option
                            v-for="item in typeList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label='标题' prop='title' :label-width="formLabelWidth">
              <el-input placeholder='请输入标题' clearable autocomplete="off" v-model='form.title' size="small"  />
            </el-form-item>
            <el-form-item label='详情' prop='detail' :label-width="formLabelWidth">
                <editor :init="editorInit" v-model="form.detail" />
            </el-form-item>
            <el-form-item label='开关' prop='ext1' :label-width="formLabelWidth">
              <el-input size="small"    v-model="form.ext1" />
          </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeDialog()">取 消</el-button>
        <el-button type="primary" @click="submitUser">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import { insertHelpdetail,updateHelpdetail,deleteHelpdetail,bacthDelHelpdetail,getHelptypeList,uploadFile } from "@/request/requests";
  import { MessageSuccess,MessageError,MessageInfo } from "@/util/util"
  import Editor from '@tinymce/tinymce-vue'
  export default {
      components: {
          Editor
      },
    name: "user",
    data(){
      const columns = [
          {type: 'selection'},
          {prop: 'id',label: '序号'},
          {prop: 'helptype.name',label: '分类'},
          {prop: 'title',label: '标题'},
          // {prop: 'detail',label: '详情'},
          {prop: 'createTime',label: '创建时间'},
          {prop: 'updateTime',label: '更新时间'},
          {prop: 'ext1',label: '开关'},
      ]
      const searchForm = [
          {
              type: 'date-picker',
              id: 'date',
              label: '时间筛选',
              el: {
                  type: 'daterange',
                  valueFormat: 'yyyy-MM-dd'
              },
              inputFormat: (row) => {
                  if (row.startdate && row.enddate) {
                      return [row.startdate, row.enddate]
                  }
              },
              outputFormat: (val) => {
                  if (!val) {
                      return ""
                  }
                  return {
                      startdate: val[0],
                      enddate: val[1]
                  }
              }
          },
          {
            type: 'input',
            id: 'title',
            label: '标题',
            el: {placeholder: '请输入标题', clearable: true},
          },
          {
            type: 'input',
            id: 'detail',
            label: '详情',
            el: {placeholder: '请输入详情', clearable: true},
          },
      ]
      return {
        columns,
        userinfo:{},
        typeList:[],
        tableConfig: {
          searchForm,
          url: 'helpdetail/list',
          dataPath: 'data.list',
          totalPath: 'data.total',
          paginationSize:10,
          extraButtons: [
            {
              type: 'primary',
              text: '编辑',
              icon: 'el-icon-edit-outline',
              show:()=>{return this.hasRole('helpdetail:update')},
              atClick: async row => {
                this.handleEdit(row)
                return false
              },
            },
            {
              type: 'primary',
              text: '删除',
              icon: 'el-icon-delete',
              show:()=>{return this.hasRole('helpdetail:delete')},
              atClick: async row => {
                this.handleDelete(row)
                return false
              },
            },
          ],
          headerButtons: [
            {
              text: '新增',
              type: 'primary',
              show:()=>{return this.hasRole('helpdetail:insert')},
              atClick: async () => {
                this.handleInsert()
                return false
              },
            },
            {
              text: '批量删除',
              type: 'primary',
              show:()=>{return this.hasRole('helpdetail:batchDelete')},
              disabled: selected => selected.length < 1,
              atClick: selected => {
                let ids = selected.map(item => item.id)
                this.Batch('delete', ids)
                return false
              },
            },
          ],
          hasDelete: false,
          hasEdit: false,
          hasOperation: true,
          hasNew: false,
          operationAttrs: {
            width: '150px',
            fixed: 'right',
          },
        },
        form:this.initForm(),
        title:"",
        type:"",
        dialogFormVisible:false,
        formLabelWidth:"100px",
        rules: {
            id :[
              {required: true, message: '请输入序号', trigger: 'blur'},],
            typeid :[
                {required: true, message: '请选择分类', trigger: 'blur'},],
            title :[
              {required: true, message: '请输入标题', trigger: 'blur'},],
            detail :[
              {required: true, message: '请输入详情', trigger: 'blur'},],
            createTime :[
              {required: true, message: '请输入创建时间', trigger: 'blur'},],
            updateTime :[
              {required: true, message: '请输入更新时间', trigger: 'blur'},],
            // ext1 :[
            //   {required: true, message: '请输入扩展字段', trigger: 'blur'},],
            ext2 :[
              {required: true, message: '请输入扩展字段', trigger: 'blur'},],
        },
          editorInit: {
              language: 'zh_CN',
              convert_urls: false,
              height: 300,
              plugins: ['advlist anchor autolink autosave code codesample colorpicker colorpicker contextmenu directionality emoticons fullscreen hr image imagetools importcss insertdatetime link lists media nonbreaking noneditable pagebreak paste preview print save searchreplace spellchecker tabfocus table template textcolor textpattern visualblocks visualchars wordcount kityformula-editor'],
              toolbar: ['bold italic underline strikethrough alignleft aligncenter alignright outdent indent  blockquote undo redo removeformat subscript superscript code codesample',
                  'hr numlist link image charmap preview anchor pagebreak insertdatetime media table emoticons forecolor backcolor fullscreen',
                  'fontselect formatselect fontsizeselect kityformula-editor'],
              images_upload_handler: function(blobInfo, success, failure) {
                  const formData = new FormData()
                  formData.append('file', blobInfo.blob())
                  uploadFile(formData).then(res => {
                      success(res.data)
                  }).catch(() => {
                      failure('上传失败，请重新上传')
                  })
              }
          }
      }
    },
    methods:{
      hasRole(val){
        return this.hasPerm(val)
      },
      initForm() {//初始数据
        return {
          id : "", // 序号
            typeid:"",
          title : "", // 标题
          detail : "", // 详情
          createTime : "", // 创建时间
          updateTime : "", // 更新时间
          ext1 : "", // 扩展字段
          ext2 : "", // 扩展字段
        }
      },
      handleEdit(row) {
        console.log(row)
        this.form = row
        this.openDialog();
        this.title = "修改信息"
        this.type = "update"
      },
      handleDelete(row) {
        this.$confirmel('此操作将永久删除该文件, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        }).then(() => {
            this.operatorDeleteUser(row)
        }).catch(() => {
            MessageInfo("已取消删除");
        });
      },
      async operatorDeleteUser(row) {
        let res = await deleteHelpdetail(row.id)
        if (res.status == "200") {
          MessageSuccess(res.msg)
        } else {
          MessageError(res.msg)
        }
        this.$refs.table.getList()
      },
      handleInsert() {
        this.openDialog();
        this.title = "新增"
        this.type = "insert"
      },
      async Batch(type, ids) {
        if (type == "delete") {
          let res = await bacthDelHelpdetail(ids)
          if (res.status == "200") {
            MessageSuccess(res.msg)
          } else {
            MessageError(res.msg)
          }
        }
        this.$refs.table.getList()
      },
      openDialog() {
        this.dialogFormVisible = true
      },
      closeDialog() {
        this.dialogFormVisible = false
        this.form = this.initForm();
        this.$refs.ruleUser.clearValidate();
        this.$refs.table.getList()
      },
      async submitUser() {
        this.$refs.ruleUser.validate((valid) => {
          if (valid) {
            this.operatorUser()
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      async operatorUser() {
        let res;
        if (this.type == "insert") {
          res = await insertHelpdetail(this.form)
          if (res.status == "200") {
            MessageSuccess(res.msg)
            this.closeDialog()
          } else {
            MessageError(res.msg)
          }
        } else if (this.type == "update") {
          res = await updateHelpdetail(this.form)
          if (res.status == "200") {
            MessageSuccess(res.msg)
            this.closeDialog()
          } else {
            MessageError(res.msg)
          }
        }
        this.$refs.table.getList()
      },getUserInfo(){
        let deadline = sessionStorage.getItem('deadline');
        let userInfo = sessionStorage.getItem("userInfo");
        let token = sessionStorage.getItem('token');
        if(!deadline||!userInfo||!token) {
          this.removeInfo()
          MessageError("请先登录！")
          this.$router.push({ path: "/login" });
          return false
        }else{
          let now = new Date().getTime();
          if (deadline<now){
            this.removeInfo()
            MessageError("请先登录！")
            this.$router.push({ path: "/login" });
            return false
          }
        }
        return userInfo;
      },
      removeInfo(){
        sessionStorage.removeItem("userInfo");
        sessionStorage.removeItem("deadline");
        sessionStorage.removeItem("token");
      },
      async getHelptypeListInfo() {
          let res = await getHelptypeList()
          if (res.status == "200") {
              this.typeList=res.data.list
          } else {
              MessageError(res.msg)
          }
      }
    },
    created() {
      let userInfo=this.getUserInfo()
      if (userInfo){
        this.userinfo=JSON.parse(userInfo)
        this.tableConfig.axiosConfig={
          headers: {
            'Authorization': 'X-KILOGOD-AUTHORIZATION '+sessionStorage.getItem('token'),

          }
        }
        if (!this.hasRole('helpdetail:update')&&!this.hasRole('helpdetail:delete')){
          this.tableConfig.hasOperation=false
        }
        this.getHelptypeListInfo()
      }
    }
  }
</script>

<style scoped>

</style>
